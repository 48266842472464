const URL = "http://web_app1:8022/api";

    
var test = {
    PathURL() { return URL }
   
}

export default test



