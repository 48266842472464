import Vue from 'vue'
import VueRouter from 'vue-router'
import "@/assets/styles/ycbdesign.css";

Vue.use(VueRouter)

const routes = [


  
  {
    path: '/',
    name: 'dashboard',
    component: () => import('./../views/Dashboard'),
    props: true,
    meta: {
      requiresAuth: true
      
     },
  },
    

    
 


    {
        path: '/',
        name: 'About',
        component: () => import('./../views/About'),
        props: true,
        meta: {
            requiresAuth: true

        },
    },
  
   
  {
    path: '/login',
    name: 'login',
    component: () => import('./../views/Login'),
  

    props: true,
    meta: {
      requiresVisitor: true,
      layout: "landing", hideNavbar: true
    },
  }, 
    
  




  
  
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

/* 
router.beforeEach((to, from, next)=>{
  let user = JSON.parse(localStorage.getItem('user'));
  if(user==null ){
  
    console.log("dddddddddddddddddddd");
   
    
  } else {
    console.log("yyyyyyyyyyyyyyyyyyyy");
    next();
  }
})*/







router.beforeEach((to, from, next) => {
    //localStorage.getItem("key"); 
    // console.log("uddddddddddd" ,localStorage.getItem('DateNow'));
    //console.log("cccccccccc" ,localStorage.getItem('DateAfter'));


    if (to.path != '/login') {
        let user = JSON.parse(localStorage.getItem('usertoken'));

        if (user == null) {


            next('login');
        } else {
            next();
        }
    } else {
        next(); // This is where it should have been
    }
    // next(); - This is in the wrong place
});




