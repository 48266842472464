<template>


    <v-app-bar app absolute elevate-on-scroll elevation="3" color="white">
        <v-app-bar-nav-icon @click="$emit('drawerEvent')"></v-app-bar-nav-icon>
        <v-spacer />
        <v-row justify="center" class="green-bg">

            <v-dialog class="green-bg"
                      v-model="MessageChange"
                      persistent
                      max-width="600px">
                <v-toolbar class="green-bg">
                    <v-card-title>     {{MessageTop}}     </v-card-title>
                </v-toolbar>


            </v-dialog>

        </v-row>


        <v-row justify="center">

            <v-dialog v-model="profiledialog"
                      persistent
                      max-width="600px">

                <v-toolbar>
                    <v-card-title>       بيانات الشركة </v-card-title>
                </v-toolbar>



                <v-card>



                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-container class="grey lighten-5">
                            <v-row justify="start">



                                <v-col cols="12"
                                       md="3"
                                       class="text-decoration-underline font-weight-bold">


                                    اسم الشركة
                                </v-col>
                                <v-col>


                                    {{CompenyNameProfile}}

                                </v-col>
                            </v-row>
                            <v-row justify="start">

                                <v-col cols="12"
                                       md="3"
                                       class="text-decoration-underline font-weight-bold">


                                    ايميل الشركة
                                </v-col>
                                <v-col>


                                    {{CompenyEmailProfile}}

                                </v-col>





                            </v-row>
                            <v-row justify="start">

                                <v-col cols="12"
                                       md="3"
                                       class="text-decoration-underline font-weight-bold">


                                    عنوان الشركة
                                </v-col>
                                <v-col>


                                    {{CompenyAddressProfile}}

                                </v-col>
                            </v-row>

                            <v-row justify="start">

                                <v-col cols="12"
                                       md="3"
                                       class="text-decoration-underline font-weight-bold">


                                    وصف الشركة
                                </v-col>
                                <v-col>


                                    {{CompenyDescriptionProfile}}

                                </v-col>





                            </v-row>



                            <br>
                            <br>
                            <br>







                        </v-container>
                    </v-card-actions>

                </v-card>

                <v-toolbar>
                    <v-card-title>       بيانات المستخدم </v-card-title>
                </v-toolbar>



                <v-card>



                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-container class="grey lighten-5">
                            <v-row justify="start">



                                <v-col cols="12"
                                       md="3"
                                       class="text-decoration-underline font-weight-bold">


                                    اسم المستخدم
                                </v-col>
                                <v-col>


                                    {{UserNameProfile}}

                                </v-col>
                            </v-row>
                            <v-row justify="start">



                                <v-col cols="12"
                                       md="3"
                                       class="text-decoration-underline font-weight-bold">


                                    اسم الموظف
                                </v-col>
                                <v-col>


                                    {{UserFullNameProfile}}

                                </v-col>
                            </v-row>
                            <v-row justify="start">

                                <v-col cols="12"
                                       md="3"
                                       class="text-decoration-underline font-weight-bold">


                                    الايميل
                                </v-col>
                                <v-col>


                                    {{UserEmailProfile}}

                                </v-col>
                            </v-row>

                            <v-row justify="start">

                                <v-col cols="12"
                                       md="3"
                                       class="text-decoration-underline font-weight-bold">


                                    رقم الهاتف
                                </v-col>
                                <v-col>


                                    {{userPhoneNumberProfile}}

                                </v-col>
                            </v-row> <v-row justify="start">

                                <v-col cols="12"
                                       md="3"
                                       class="text-decoration-underline font-weight-bold">


                                    الرقم الوظيفي
                                </v-col>
                                <v-col>


                                    {{userEmployeeIdProfile}}

                                </v-col>
                            </v-row>


                            <br>
                            <br>
                            <br>

                            <v-row justify="end">


                                <v-btn class="ml-5 success"
                                       @click="profiledialog = false">
                                    اغلاق
                                </v-btn>
                            </v-row>






                        </v-container>
                    </v-card-actions>

                </v-card>

            </v-dialog>

        </v-row>




        <v-row justify="center">

            <v-dialog v-model="dialogEdit"
                      persistent
                      max-width="600px">
                <v-toolbar class="tol">
                    <v-card-title>  تغيير كلمة السر</v-card-title>
                </v-toolbar>

                <v-card>



                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-container class="grey lighten-5">
                            <v-form @submit.prevent="ChangepasswordFun">

                                <v-row justify="start">
                                    <v-col>
                                        <v-text-field type="password"
                                                      v-model="passwordold"
                                                      placeholder=" كلمة السر القديمة "></v-text-field>

                                    </v-col>
                                </v-row>

                                <v-row justify="start">
                                    <v-col>
                                        <v-text-field type="password"
                                                      v-model="password1"
                                                      placeholder="كلمة السر الجديدة "></v-text-field>

                                    </v-col>
                                </v-row>

                                <v-row justify="start">
                                    <v-col>
                                        <v-text-field type="password"
                                                      v-model="password2"
                                                      placeholder="تاكيد كلمة السر  "></v-text-field>
                                    </v-col>
                                </v-row>
                                <br>
                                <span style="color:red">{{MessageTop}} </span>
                                <v-row justify="end">
                                    <v-btn color="primary"
                                           class="ml-5 "
                                           @click="Changepassword">
                                        تغيير كلمة السر
                                    </v-btn>

                                    <v-btn class="ml-5 success"
                                           @click="dialogEdit = false">
                                        اغلاق
                                    </v-btn>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-row>


        <!--<v-row justify="center">

        <v-dialog v-model="dialogEditCompeny"
                  persistent
                  max-width="600px">
            <v-toolbar class="tol">
                <v-card-title>  تغيير  بيانات الشركة</v-card-title>
            </v-toolbar>

            <v-card>



                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-container class="grey lighten-5">
                        <v-form @submit.prevent="ChangeCompunyFun">

                            <v-row justify="start">
                                <v-col>
                                    <v-text-field v-model="Compenyname"
                                                 label=" اسم الشركة "
                                                 ></v-text-field>



                                </v-col>
                            </v-row>

                            <v-row justify="start">
                                <v-col>


                                    <v-text-field
                                                label="ايميل الشركة "
                                                  v-model="CompenyEmail"
                                                rtl></v-text-field>



                                </v-col>
                            </v-row>
                            <v-row justify="start">
                                <v-col>
                                    <v-text-field v-model="CompenyAddress"
                                                  label="عنوان الشركة "></v-text-field>

                                </v-col>
                            </v-row>

                            <v-row justify="start">
                                <v-col>


                                    <v-textarea label="وصف الشركة "
                                                v-model="CompenyDescription"
                                                rtl></v-textarea>

                                </v-col>
                            </v-row>
                            <br>
                            <span style="color:red">{{MessageTop}} </span>
                            <v-row justify="end">
                                <v-btn color="primary"
                                       class="ml-5 "
                                       @click="ChangeCompunyFun">
                                    تغيير
                                </v-btn>

                                <v-btn class="ml-5 success"
                                       @click="dialogEditCompeny = false">
                                    اغلاق
                                </v-btn>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-row>-->
        <v-col lg="6" cols="12">

        </v-col>
        <v-spacer />
        <!--<v-menu offset-y>-->
            <!--<template v-slot:activator="{ attrs, on }">
            <span class="mx-5 mr-10"
                  style="cursor: pointer"
                  v-bind="attrs"
                  v-on="on">
                <v-badge content="3" color="red" offset-y="10" offset-x="10">
                    <v-icon>mdi-bell</v-icon>
                </v-badge>
            </span>
        </template>-->
            <!--<v-list three-line width="250">
                <template v-for="(item, index) in items">
                    <v-subheader v-if="item.header"
                                 :key="item.header"
                                 v-text="item.header"></v-subheader>

                    <v-divider v-else-if="item.divider"
                               :key="index"
                               :inset="item.inset"></v-divider>

                    <v-list-item v-else :key="item.title">
                        <v-list-item-avatar>
                            <v-img :src="item.avatar"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-html="item.title"></v-list-item-title>
                            <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-menu>-->
        <v-menu offset-y>
            <template v-slot:activator="{ attrs, on }">
                <span style="cursor: pointer" v-bind="attrs" v-on="on">
                    <v-chip link>
                        <v-badge dot bottom color="green" offset-y="10" offset-x="10">
                            <v-avatar size="40">
                                <img src="../assets/man.png" />
                            </v-avatar>
                        </v-badge>
                        <span class="ml-3">{{ username }}</span>
                    </v-chip>
                </span>
            </template>
            <v-list width="250" class="py-0">
                <v-list-item two-line>
                    <v-list-item-avatar>
                        <img src="../assets/man.png" />


                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>  {{ username }}</v-list-item-title>
                        <v-list-item-subtitle>Logged In</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-list-item @click="handle(menu.send)" v-for="(menu, i) in menus" :key="i">
                    <v-list-item-icon>
                        <v-icon>{{ menu.icon }}</v-icon>
                    </v-list-item-icon>


                    <v-list-item-title>
                        {{ menu.title }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
  
</template>

<script>
//var data = JSON.parse(localStorage.getItem("user"));
//var username =this.user.userName;


import axios from 'axios';
    import test from '@/UrlPath'
export default {
  name: "Topbar",
  mounted() {
      if (JSON.parse(localStorage.getItem('usertoken'))) {
     //     var user = JSON.parse(localStorage.getItem('usertoken'));
     /*this.username=name.userName;*/

          this.GetCompUserFun();


      }

  },
  data() {

    
      return {
          URLLink: test.PathURL(),
          CompenyNameProfile: "",
          CompenyAddressProfile: "",
          CompenyDescriptionProfile: "",
          CompenyEmailProfile: "",
          CompenyDescription: "",
          CompenyAddress: "",
          Compenyname: "",
          CompenyEmail: "",

UserEmailProfile:"",
          UserNameProfile: "",
          UserFullNameProfile: "",
userEmployeeIdProfile:"",
userPhoneNumberProfile:"",

 regularExpression : /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,

          MessageTop:"",
      password1:"",
passwordold:"",
            password2:"",
 MessageChange: false,
        profiledialog: false,

        dialogEditCompeny: false,
 dialogEdit: false,
    //user :JSON.parse(localStorage.getItem('user')),
//username :this.user.userName,
username: "",

      menus: [
          { title: "الملف الشخصي", icon: "mdi-account" , send: "ProfileFun"},
        { title: "تغيير كلمة السر", icon: "mdi-key", send: "ChangePasswordFun" },
        //  { title: "إعدادات", icon: "mdi-cog", send: "ShowCompenyDialogFun"},
        { title: "تسجيل خروج", icon: "mdi-logout", send: "LogoutFun" },
      ],
      
    };
  },









  
 methods:{ 

      switchVisibility() {
      if (this.password1.getAttribute('type') === 'password') this.password1.setAttribute('type', 'text')
      else this.password1.setAttribute('type', 'password')
    },                             
handle(f){
      this[f]();
    },





     GetCompUserFun() {
         let usertoken = JSON.parse(localStorage.getItem('usertoken'));
         /*  console.log('userrrrr 222222 ', usertoken)*/


         const headers = {
             'Content-Type': 'application/json',
             'Authorization': ` ${usertoken}`
         }
         //console.log('userrrrr ', usertoken)


         //this.profiledialog = !this.profiledialog

         axios.get(this.URLLink + '/TejariSalary/GetMyProfile', { headers: headers }).then((res) => {

             //console.log('dsfgfgfgfgfgfgfgfgfgfg  ', res.data)

             this.username = res.data.user.username;

             localStorage.setItem('username', JSON.stringify(res.data.user.username));
             localStorage.setItem('userFullname', JSON.stringify(res.data.user.fullname));


             localStorage.setItem('userEmail', JSON.stringify(res.data.email));
             localStorage.setItem('mobile', JSON.stringify(res.data.user.mobile));
             localStorage.setItem('Company', JSON.stringify(res.data.company));

             //this.UserNameProfile = res.data.username;

             //this.UserEmailProfile = res.data.userEmail;
             //this.userPhoneNumberProfile = res.data.mobile;
             //this.userEmployeeIdProfile = res.data.userEmployeeId;




         })



     },

ProfileFun( ){
  //  let usertoken = JSON.parse(localStorage.getItem('usertoken'));
  /*  console.log('userrrrr 222222 ', usertoken)*/

   
//const headers = {
//  'Content-Type': 'application/json',
//    'Authorization': ` ${usertoken}`
//}
    //console.log('userrrrr ', usertoken)

   
    this.profiledialog = !this.profiledialog
    
    
      
    this.UserNameProfile = localStorage.getItem('username');
    this.UserFullNameProfile = localStorage.getItem('userFullname');

    this.UserEmailProfile = localStorage.getItem('userEmail');
    this.userPhoneNumberProfile = localStorage.getItem('mobile');

    //console.log('Company ', localStorage.getItem('Company'));
    var Company = JSON.parse(localStorage.getItem('Company'));
    
    this.CompenyNameProfile = Company.name;
    this.CompenyAddressProfile = Company.address;
    this.CompenyDescriptionProfile = Company.description;
    this.CompenyEmailProfile = Company.email;




 /*   })*/



        },

ChangePasswordFun
( ){

   
    this.dialogEdit = !this.dialogEdit
    



        },
     ShowCompenyDialogFun
         () {


         this.dialogEditCompeny = !this.dialogEditCompeny

         var Company = JSON.parse(localStorage.getItem('Company'));





         this.Compenyname = Company.name;

         this.CompenyAddress = Company.address;
         this.CompenyDescription = Company.description;
         this.CompenyEmail = Company.email;


     },




 Changepassword(){

     let usertoken = JSON.parse(localStorage.getItem('usertoken'));

   
const headers = {
  'Content-Type': 'application/json',
    'Authorization': ` ${usertoken}`
}
              //  console.log(this.password1)



              if( this.password1==this.password2  )
               {     
                
                
                
                
                  axios.post(this.URLLink + '/TejariSalary/ChangeMyPassword',{
     oldPassword:this.passwordold.toString(),
      password:this.password1.toString()
    }, { headers: headers }).then((res) => {
              
if(     res.data. error !=null  ){

    this.MessageTop =res.data.error ;

    

}
         else{      
           this.dialogEdit = false;
    this.MessageTop =" تم تعديل كلمة السر" ;
                             this.MessageChange = true;

setTimeout(() =>this. MessageChange = false, 900)     


         }
               //fetch('./Finance_Required').then(res => console.log(res))
                // this.$router.push({name:'sectors'})
    }).catch((error) => {
        this.Sucess = false;

        //console.log('FAILURE!!', error);


        //this.Message = " اسم المستخدم او كلمة السر غير صحيحة";


        //console.log(error.response.status) // 401
        //console.log('jhgjgj', error.response) //Please Authenticate or whatever returned from server

        if (error.response.status == 401) {

            window.location.href = "../Login"

        }
        //if (error.response.status == 401) {
        //    //redirect to login
        //    this.Message = " خطاء في البيانات المرسلة";
        //    this.Errorr = error.response.data.errors;

        //}
        if (error.response.status == 400) {
            //redirect to login
            this.MessageTop = " خطاء في البيانات المرسلة";
            this.Errorr = error.response.data;

        }
        if (error.response.status == 500) {
            //redirect to login
            this.MessageTop = " خطاء في السيرفر ";
            //this.Errorr = " اسم المستخدم او كلمة السر غير صحيحة";

        }
        if (error.response.status == 404) {
            //redirect to login
            this.MessageTop = " غير موجود ";
            this.Errorr = error.response.data;

        }
        this.snackbarWerning = true;

    })

}
else
   {

                  this.MessageTop ="كلمة السر ليست مطابقة";

   //  console.log("كلمة السر ليست مطابقة")
   }


           },
          
    LogoutFun(){


//this.$store.state.Tokenuser=null;


        localStorage.removeItem('usertoken');
        localStorage.removeItem('username');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('mobile');
        localStorage.removeItem('batchId');
        localStorage.removeItem('usertoken');
        localStorage.removeItem('usertoken');

           this.$router.push('/login');
   


        },

       
  
    


 }
};
</script>

<style scoped>
    .btnAdd {
        border: 2px solid black;
        background-color: white;
        color: black;
        padding: 14px 28px;
        font-size: 16px;
        cursor: pointer;
    }

    /* Green */
    .Add {
        border-color: #2a2d93;
        color: #2a2d93;
    }

        .Add:hover {
            background-color: #2a2d93;
            color: white;
        }






    button, input, optgroup, select, textarea {
        font-size: 0.82rem;
    }
    *[data-v-7ba5bd90] {
        font-size: 0.82rem;
    }

    .invisible {
        visibility: hidden;
    }

    .v-toolbar__title {
        font-size: 1.1rem;
    }

    .v-card__title {
        font-size: 1.1rem;
    }

    .v-card__title {
        line-height: 1.1rem;
    }

    .v-btn.v-size--default {
        font-size: 0.7rem;
    }

    body {
        font-size: 0.8rem;
    }
    .v-btn.v-size--default {
        font-size: 0.75rem;
    }
    .v-list-item__title {
        font-size: 0.7rem;
    }
.v-text-field--outlined >>> fieldset {
  border-color: #f5f5f5;
  color:#f5f5f5;
}
.v-text-field--outlined >>> fieldset::placeholder {
  color: green;
}
.green-bg {
  /* Set the display to `table-row` because Vuetify makes `flex` */
    background: #4caf50 !important; 
color:#f5f5f5;

}

.theme--light.v-btn.v-btn--icon {
    color: #fafafa;
}
    .v-application .white {
        background-color: rgb(42 45 147) !important;
        border-color: #0a385c !important;
    }
.red-bg {
  /* Set the display to `table-row` because Vuetify makes `flex` */
    background: #e22416 !important; 
color:#f5f5f5;

}
</style>
