// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Merienda&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lemonada:wght@300;400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v-sheet.v-card[data-v-c541de4a]:not(.v-sheet--outlined){box-shadow:0 3px 1px -2px rgba(107,207,246,.73),0 2px 2px 0 rgba(107,207,246,.69),0 1px 5px 0 rgba(107,207,246,.46)!important}.v-navigation-drawer[data-v-c541de4a]{-webkit-overflow-scrolling:touch;display:flex;flex-direction:column;right:0!important;max-width:100%;overflow:hidden;pointer-events:auto;top:0;transition-duration:.2s;transition-timing-function:cubic-bezier(.4,0,.2,1);transition-property:transform,visibility,width}*[data-v-c541de4a]{font-family:Lemonada,cursive;font-size:.75rem}.dir[data-v-c541de4a]{direction:rtl!important}.v-application--is-ltr .v-list-group--no-action>.v-list-group__items>.v-list-item[data-v-c541de4a]{padding-left:0;padding-right:70px!important}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
