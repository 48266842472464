import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from "axios";

//import '../src/assets/style.css';

//mport "./assets/styles/ycbdesign.css";
require("./assets/styles/ycbdesign.css");

axios.interceptors.response.use(undefined, async function (error) {
    if (error.response.status === 401) {
        await store.dispatch("auth/logout");
        router.push("/login");
    }
    return Promise.reject(error);
});
//import router from './router/index.js'
Vue.config.productionTip = false

new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')
