<template>
  <v-app id="inspire" dir="rtl">
    <v-main style="background: #f5f5f540">
      <v-container class="py-8 px-6" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <Topbar @drawerEvent="drawer = !drawer"  v-if="!$route.meta.hideNavbar" />
    
    
  </v-app>
</template>

<script>
import Topbar from "./components/Topbar";

import Vue from 'vue'
import Vuetify from 'vuetify'


//var hours = 1; // to clear the localStorage after 1 hour
               // (if someone want to clear after 8hrs simply change hours=8)
var now = new Date().getTime();
var setupTime = localStorage.getItem('setupTime');
if (setupTime == null) {
    localStorage.setItem('setupTime', now)
} else {
    if (now - setupTime > 1000 * 60 * 15) {
        localStorage.clear()
        localStorage.setItem('setupTime', now);
    }
}



Vue.use(Vuetify, {
  rtl: true
})

export default {
  
  name: "App",
  components: { Topbar },
  data: () => ({ 
    cards: ["Today", "Yesterday"],
    drawer: null,
  }),
  methods: {

    
  },
};
</script>
<style scoped >

    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: 0px 3px 1px -2px rgb(107 207 246 / 73%), 0px 2px 2px 0px rgb(107 207 246 / 69%), 0px 1px 5px 0px rgb(107 207 246 / 46%) !important;
       
    }
    @import url('https://fonts.googleapis.com/css2?family=Merienda&display=swap');

/*    @import url('https://fonts.googleapis.com/css2?family=Lemonada:wght@700&display=swap');
*/   



    @import url('https://fonts.googleapis.com/css2?family=Lemonada:wght@300;400;700&display=swap');



.v-navigation-drawer {
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
    /* left: 0; */
    right: 0 !important;
    max-width: 100%;
    overflow: hidden;
    pointer-events: auto;
    top: 0;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: transform, visibility, width;
}
    * {
        font-family: 'Lemonada', cursive;
        font-size: 0.75rem;
    }


   
  
.dir {
  direction: rtl !important;
}


.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
       padding-left: 0px; 
    padding-right: 70px !important;
}
</style>